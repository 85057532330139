/* You can add global styles to this file, and also import other style files */

$background-gradient-start-color: var(--background-gradient-start-color);
$background-gradient-middle-color: var(--background-gradient-middle-color);
$background-gradient-end-color: var(--background-gradient-end-color);
$background-gradient-start-color-rgb: var(--background-color-rgb);

$button-background-gradient-start-color: var(--button-background-gradient-start-color);
$button-background-gradient-middle-color: var(--button-background-gradient-middle-color);
$button-background-gradient-end-color: var(--button-background-gradient-end-color);
$button-background-gradient-start-color-rgb: var(--button-background-color-rgb);

$button-text-gradient-start-color: var(--button-text-gradient-start-color);
$button-text-gradient-middle-color: var(--button-text-gradient-middle-color);
$button-text-gradient-end-color: var(--button-text-gradient-end-color);
$button-text-gradient-start-color-rgb: var(--button-text-color-rgb);

$text-gradient-start-color: var(--text-gradient-start-color);
$text-gradient-middle-color: var(--text-gradient-middle-color);
$text-gradient-end-color: var(--text-gradient-end-color);
$text-gradient-start-color-rgb: var(--text-color-rgb);

$white-color: #f0f0f0;
$black-color: #1e1e1e;
$terminal-color: #55e357;
$success: #55e357;
$failure: #cc2828;

html,
body {
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: calc(100vh - calc(100vh - 100%));
  background-color: $background-gradient-start-color;
  margin: 0;
  padding: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  user-select: none;
  touch-action: none;

  @media screen and (orientation: portrait) {
    font-size: clamp(1.0vw, 1.5vw, 2.0vw);

  }

  @media screen and (orientation: landscape) {
    font-size: clamp(1.0vh, 1.5vh, 2.0vh);
  }
}

iframe {
  position: fixed;
  overflow: hidden;
  width: 100vw;
  height: calc(100vh - calc(100vh - 100%));
  background-color: $background-gradient-start-color;
  margin: 0;
  padding: 0;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  user-select: none;
  touch-action: none;

}

@mixin humetips-flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}


.mat-progress-spinner circle, .mat-spinner circle {
  stroke: $background-gradient-start-color;
}


::-webkit-scrollbar {
  display: none;
}

::ng-deep mat-keyboard-container {
  min-width: 100% !important;
}

::ng-deep mat-keyboard-key {
  height: 5rem;
}


@mixin hti-gradient-text($startColor, $middleColor, $endColor) {
  background-color: $startColor;
  background-image: linear-gradient(120deg, $startColor 0%, $middleColor 50%, $endColor 100%);
  background-size: 100%;
  background-clip: text;
  color: transparent;
}

::ng-deep .white-gradient-letter {
  @include hti-gradient-text($white-color, $white-color, $white-color);
}

::ng-deep .background-gradient-letter {
  @include hti-gradient-text($background-gradient-start-color, $background-gradient-middle-color, $background-gradient-end-color);
}

::ng-deep .button-background-gradient-letter {
  @include hti-gradient-text($button-background-gradient-start-color, $button-background-gradient-middle-color, $button-background-gradient-end-color);
}

::ng-deep .button-text-gradient-letter {
  @include hti-gradient-text($button-text-gradient-start-color, $button-text-gradient-middle-color, $button-text-gradient-end-color);
}

::ng-deep .text-gradient-letter {
  @include hti-gradient-text($text-gradient-start-color, $text-gradient-middle-color, $text-gradient-end-color);
}

@mixin hti-gradient-border($backgroundColor, $border-width, $startColor, $middleColor, $endColor) {
  background: linear-gradient($backgroundColor, $backgroundColor) padding-box,
  linear-gradient(120deg, $startColor 0%, $middleColor 50%, $endColor 100%) border-box;
  border: $border-width solid transparent;
}

@mixin hti-gradient-border-with-background($borderStartColor, $borderMiddleColor, $borderEndColor, $border-width,
  $backgroundStartColor, $backgroundMiddleColor, $backgroundEndColor) {
  background: linear-gradient(120deg, $backgroundStartColor 0%, $backgroundMiddleColor 50%, $backgroundEndColor 100%) padding-box,
  linear-gradient(120deg, $borderStartColor 0%, $borderMiddleColor 50%, $borderEndColor 100%) border-box;
  border: $border-width solid transparent;
}

@mixin hti-checkbox-checked-gradient-border($backgroundColor, $border-width, $startColor, $middleColor, $endColor) {
  background: linear-gradient(120deg, $startColor 0%, $middleColor 50%, $endColor 100%) content-box,
  linear-gradient($backgroundColor, $backgroundColor) padding-box,
  linear-gradient(120deg, $startColor 0%, $middleColor 50%, $endColor 100%) border-box;
  border: $border-width solid transparent;
}

@mixin hti-gradient-mat-icon($startColor, $middleColor, $endColor) {
  background-color: $startColor;
  background: linear-gradient(120deg, $startColor 0%, $middleColor 50%, $endColor 100%);
  background-size: 100%;
  background-clip: text;
  color: transparent;
}

@import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
